<template>
  <div class="order-details-component flex justify-between flex-col bg-white">
    <div>
      <div
        class="bg-red-450 order-details-header flex justify-between py-4 px-5 text-white"
        :class="{
          'bg-red-450': order.status == 'out-of-sla',
          'bg-green-450': order.status == 'in-sla',
        }"
      >
        <p>
          <span class="font-bold block">{{ order?.kitchen_picker?.name }}</span>
          <span class="flex items-end date"> {{ order.time }}</span>
        </p>
        <p>
          <span class="block">طلب رقم </span>
          <span class="font-bold number">{{ order.order_number }}</span>
        </p>
      </div>

      <div class="px-5">
        <section v-for="(item, itemIndex) in items" :key="itemIndex + item.id" class="border-b border-gray-300 py-2">
          <div class="flex justify-between items-center py-2">
            <section
              class="leading-loose"
              :class="[
                item.needRemoval ? 'w-full' : 'w-1/2',
                item.status == 'cancelled' ? 'line-through opacity-50' : '',
              ]"
            >
              <span class="font-bold">
                {{ item.displayedQuantity }}
              </span>

              <span v-if="item.unit == 'G'" class="font-bold"> جرام </span>
              <span v-else-if="item.unit == 'KG'" class="font-bold"> كيلو </span>

              <span class="font-semibold"> - {{ item.name }} </span>
              <p v-if="item.customer_comment">"{{ item.customer_comment }}"</p>
            </section>

            <section v-if="!item.needRemoval" class="w-1/2 flex items-center">
              <div class="w-1/2 mb-1">
                <input
                  v-model="verifiedQuantity[itemIndex]"
                  type="text"
                  class="w-1/2 border border-green-600 rounded-md px-1 outline-none text-md"
                  :placeholder="(item.qty - item.picked_qty).toFixed(3) * (item.unit == 'G' ? 1 : 1000)"
                />
                <span> جرام </span>
              </div>
              <div v-show="item.status != 'NOT_PICKED' && item.is_prepared == 1" class="w-1/2 flex justify-around">
                <img
                  src="@/assets/min/confirm-active.svg"
                  class="cursor-pointer"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                />
                <img
                  src="@/assets/min/cancel.svg"
                  class="cursor-pointer"
                  @click="changeItemStatus(itemIndex, item, 'NOT_PICKED')"
                />
              </div>

              <div v-show="item.status == 'NOT_PICKED' && item.is_prepared == 1" class="w-1/2 flex justify-around">
                <img
                  src="@/assets/min/confirm.svg"
                  class="cursor-pointer"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                />
                <img src="@/assets/min/cancel-active.svg" alt="" />
              </div>

              <div v-show="item.is_prepared != 1" class="w-1/2 flex justify-around">
                <img
                  src="@/assets/min/confirm.svg"
                  alt="Confirm Icon"
                  class="cursor-pointer"
                  @click="changeItemStatus(itemIndex, item, 'IN_PROGRESS')"
                />
                <img
                  src="@/assets/min/cancel.svg"
                  alt="Cancel Icon"
                  class="cursor-pointer"
                  @click="changeItemStatus(itemIndex, item, 'NOT_PICKED')"
                />
              </div>
            </section>
          </div>
          <div v-if="item.needRemoval" class="w-3/5 mx-auto">
            <p class="text-sm bg-red-300 rounded-lg py-1 text-red-900 text-center font-bold">جاري استرجاع الكمية</p>
          </div>
          <div v-if="item.isDone" class="w-1/2 mx-auto">
            <p class="text-sm bg-green-450 rounded-lg py-1 text-white text-center font-bold">تم تحضير هذا العنصر</p>
          </div>
        </section>
      </div>
    </div>

    <div class="mt-5">
      <button
        class="
          finish-order-btn
          bg-green-750
          py-3
          w-4/5
          mx-auto
          block
          text-white
          my-4
          shadow-xl
          focus:outline-none
          rounded-lg
        "
        :disabled="!canFinish"
        @click="emitFinishOrderEvent()"
      >
        إنهاء الطلب
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: { type: Object, required: true },
    tolerance: {
      type: Number,
      required: true,
    },
  },
  emits: ['newOrderStatus', 'finishOrderClick'],

  data() {
    return {
      items: '',
      verifiedQuantity: [],
    };
  },

  computed: {
    canFinish() {
      if(!this.items.length)
          return true;
        return this.items.every(item => {
          return item.is_prepared == 1;
        });
    },
  },

  mounted: function () {
    this.items = this.order.items;

    this.items.forEach(item => {
      const quantityDifference = item.qty - item.picked_qty;
      const margin = this.tolerance * item.qty;

      if (Math.abs(quantityDifference) > margin) {
        item.displayedQuantity = Math.abs(quantityDifference).toFixed(3);
        item.needRemoval = quantityDifference < margin;
      } else {
        item.displayedQuantity = item.qty.toFixed(3);
        item.isDone = true;
      }
    });

    setTimeout(() => {
      document.querySelector('input[type=text]').focus();
    }, 50);
  },

  methods: {
    changeItemStatus(index, item, newStatus) {
      this.$emit('newOrderStatus', {
        itemNumber: index,
        itemID: item.id,
        itemQuantity: parseInt(this.verifiedQuantity[index])
          ? parseInt(item.picked_qty * (item.unit == 'G' ? 1 : 1000)) + parseInt(this.verifiedQuantity[index])
          : item.qty * (item.unit == 'G' ? 1 : 1000),
        itemStatus: newStatus,
      });
    },

    updateQuantity(index) {
      if (this.verifiedQuantity[index]) {
        this.items[index].picked_qty += this.verifiedQuantity[index] / (this.items[index].unit == 'G' ? 1 : 1000);
        this.verifiedQuantity[index] = '';
      }
    },

    emitFinishOrderEvent() {
      this.$emit('finishOrderClick', { id: this.order.id });
    },
  },
};
</script>

<style scoped>
.finish-order-btn:active {
  box-shadow: none;
}

.order-details-header > p {
  line-height: 2;
}

.order-details-header {
  box-shadow: inset 0 20px 15px -10px rgba(255, 255, 255, 0.9);
}

.order-details-component {
  box-shadow: 20px 0px 30px rgba(82, 80, 80, 0.1);
  min-height: calc(100vh - 75px);
}
</style>
