<template>
  <div class="absolute top-0 z-50 w-full h-full">
    <div class="absolute w-full h-full bg-black opacity-50" />
    <div class="card bg-white p-4 mx-auto z-50 fixed">
      <img
        src="@/assets/min/StartOrderModalClosingIcon.svg"
        class="cursor-pointer"
        alt="closing icon"
        @click="emitCloseModalEvent()"
      >

      <div class="py-3 px-10 mx-10">
        <p class="mb-4 text-center">
          أدخل الكود الخاص بك
        </p>

        <form
          class="my-4"
          action=""
          @submit.prevent="startOrderWithCode"
        >
          <div class="inline-block ml-5">
            <label
              class="mb-2 block"
              for="code"
            >الكود</label>
            <input
              v-model="code"
              type="text"
              placeholder="1234"
              required
              class="px-4 py-2 block border rounded-lg outline-none shadow-lg w-full"
            >
          </div>

          <input
            class="
              start-order-btn
              bg-green-750
              text-white
              px-6
              py-2
              rounded-lg
              shadow-xl
              focus:outline-none
              cursor-pointer
            "
            type="submit"
            value="ابدأ الطلب"
          >
        </form>

        <p
          v-if="!validCode"
          class="text-md text-red-600"
        >
          لقد قمت بإدخال كود خطأ، برجاء التأكد و إعادة المحاولة
        </p>
        <p
          v-if="!canStart"
          class="text-md text-red-600"
        >
          هذا كود غير مسموح به في هذا القسم، أو يوجد طلب قيد التحضير
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    validCode: {
      type: Boolean,
      required: true,
    },
    canStart: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['closeModalClick', 'newOrderCode'],

  data: () => {
    return {
      code: '',
    };
  },

  mounted: function () {
    document.querySelector('input[type=text]').focus();
  },

  methods: {
    emitCloseModalEvent() {
      this.$emit('closeModalClick');
    },

    startOrderWithCode() {
      if (this.code) {
        this.$emit('newOrderCode', { code: this.code });
      }
    },
  },
};
</script>

<style>
.card {
  top: 30vh;
  left: 30vw;
}
</style>
