<template>
  <div class="z-10 text-center mt-10 border-2 xl:w-1/3 lg:w-2/5 md:w-1/2 sm:w-2/3">
    <div class="text-center mt-5">
      <img
        src="@/assets/min/mark.svg"
        class="mark inline"
      >
      <p class="my-8 text-2xl font-light">
        لا يوجد طلبات قيد التحضير، برجاء البدء بتحضير طلب جديد الان
      </p>
      <button
        class="bg-green-750 text-white text-lg px-6 py-3 w-max rounded-xl focus:outline-none"
        :disabled="!hasOrders"
        @click="emitNewOrderClick"
      >
        <img
          src="@/assets/min/plus-order.svg"
          class="inline ml-3"
          alt="Plus Sign"
        >
        ابدأ طلب جديد
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: { hasOrders: { type: Number, required: true } },
  emits: ['newOrderClick'],
  methods: {
    emitNewOrderClick() {
      this.$emit('newOrderClick');
    },
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  min-height: 100%;
}
</style>
