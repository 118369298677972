<template>
  <div class="h-screen flex flex-col justify-center text-center">
    <h2 class="text-4xl font-bold mb-10">
      قم باختيار القسم الخاص بك
    </h2>
    <div class="[ flex justify-evenly flex-col lg:flex-row ] w-1/2 items-center mx-auto h-80 lg:h-40">
      <button
        class="shadow-2xl"
        @click="newCategory(1)"
      >
        قسم اللحوم
      </button>
      <button
        class="shadow-2xl"
        @click="newCategory(2)"
      >
        قسم الجبن
      </button>
      <button
        class="shadow-2xl"
        @click="newCategory(3)"
      >
        قسم الأسماك
      </button>
      <button
        class="shadow-2xl"
        @click="newCategory(4)"
      >
        قسم الدواجن
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['categorySelection'],
  methods: {
    newCategory(num) {
      this.$emit('categorySelection', { categoryIndex: num });
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  background: theme('colors.green.750');
  cursor: pointer;
  padding: 22.5px 45px;
  border-radius: 50px;
  margin: 0px 25px;
  font-size: 20px;
  outline: none;
  white-space: nowrap;
}
</style>
