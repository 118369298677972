<template>
  <div class="z-40 opened-menu bg-white min-h-screen">
    <div class="flex items-start">
      <img
        src="@/assets/min/close.svg"
        class="cursor-pointer pt-4 pr-4 mt-3 mr-3"
        alt="Close icon"
        @click="emitCloseMenuEvent()"
      >
      <img
        src="@/assets/min/menu-logo.svg"
        class="logo px-5 lg:px-10 pt-5 mx-2 lg:mx-5"
        alt="Logo"
      >
    </div>

    <div>
      <h3 class="mt-5">
        الأقسام
      </h3>
      <ul>
        <li @click="notifyCategortySelection(1)">
          <a> قسم اللحوم</a>
        </li>
        <li @click="notifyCategortySelection(2)">
          <a> قسم الجبن</a>
        </li>
        <li @click="notifyCategortySelection(3)">
          <a> قسم الأسماك</a>
        </li>
        <li @click="notifyCategortySelection(4)">
          <a> قسم الدواجن</a>
        </li>
      </ul>
    </div>

    <div>
      <h3 class="mt-8">
        الإعدادات
      </h3>
      <ul>
        <li @click="logout">
          <a class="logout">تسجيل الخروج</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['closeMenuClick', 'categorySelection', 'logoutClick'],
  methods: {
    emitCloseMenuEvent() {
      this.$emit('closeMenuClick');
    },

    notifyCategortySelection(num) {
      this.$emit('categorySelection', { categoryIndex: num });
    },

    logout() {
      this.$emit('logoutClick');
    },
  },
};
</script>

<style scoped>
ul {
  margin: 15px 0px;
}

ul li {
  padding: 10px 50px;
  font-size: 22px;
}

ul li:hover {
  border-right: 7px solid theme('colors.green.750');
  padding: 10px 43px;
  font-weight: bold;
  cursor: pointer;
}

h3 {
  padding: 10px 50px;
  font-size: 20px;
  color: theme('colors.gray.250');
}

.logout {
  color: theme('colors.red.650');
}

div:last-of-type > ul > li:last-of-type:hover {
  border-right: 7px solid theme('colors.red.650');
}
</style>
