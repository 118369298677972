<template>
  <Category
    v-if="isAuthenticated"
    @setAuthentication="storeTokens"
    @removeAuthentication="removeTokens"
  />
  <Login
    v-else
    @setAuthentication="storeTokens"
  />
</template>

<script>
import Category from '@/Category';
import Login from '@/Login';

export default {
  name: 'App',
  components: {
    Category,
    Login,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },

  mounted: function () {
    this.isAuthenticated = document?.cookie
      ?.split('; ')
      ?.filter(row => row.startsWith('access'))[0]
      ?.split('=')[1]
      ? true
      : false;
  },

  methods: {
    removeTokens() {
      this.isAuthenticated = false;
      localStorage.removeItem('refreshToken');
      sessionStorage.removeItem('categoryIndex');
      document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    },

    storeTokens(data) {
      document.cookie = `access_token=${data.token_type} ${data.access_token}; max-age=${data.expires_in}; path=/`;
      localStorage.setItem('refreshToken', data.refresh_token);
      this.isAuthenticated = true;
    },
  },
};
</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

html {
  height: 100%;
}

body {
  min-height: 100%;
}
* {
  direction: rtl;
  font-family: 'Cairo';
}

.date::before {
  content: ' ';
  float: right;
  background: url('assets/min/time.svg') center no-repeat;
  padding: 15px 10px;
  margin-left: 5px;
}

.number::before {
  content: '#';
}

button:hover {
  background: theme('colors.green.650');
}

button:active {
  background: theme('colors.green.650');
  box-shadow: none;
}

button:disabled {
  background: gray;
  cursor: not-allowed;
  @apply shadow-xl;
}

button:disabled div {
  color: gray;
}

button:disabled:active {
  background: gray;
  @apply shadow-xl;
}
</style>
