<template>
  <div class="grid grid-cols-5 gap-0 bg-white">
    <div class="col-start-1 col-end-3 bg-green-750 flex flex-row justify-center items-center min-h-screen">
      <div class="w-3/4 lg:w-full">
        <img
          src="@/assets/min/logo.svg"
          class="mx-auto"
          alt="Seoudi Logo"
        >
        <h3 class="text-2xl lg:text-4xl text-white font-bold text-center mt-10">
          نظام عرض المطبخ
        </h3>
      </div>
    </div>

    <div class="col-start-3 col-end-6 flex flex-row justify-center items-center min-h-screen">
      <div class="w-3/4 lg:w-3/5">
        <h2 class="text-3xl">
          تسجيل الدخول
        </h2>
        <form @submit.prevent="getAuth()">
          <div class="my-6 flex flex-col">
            <label
              class="p-2"
              for="branch"
            >اختر الفرع</label>
            <select
              v-model="selectedBranch"
              class="branch-menu py-4 px-2 rounded-lg text-black-primary bg-gray-150 outline-none"
              name="branch"
              required
            >
              <option
                value=""
                selected
                disabled
                hidden
              >
                إختر ...
              </option>
              <option
                v-for="branch in branchesList"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>

          <div class="my-6 flex flex-col">
            <div class="p-2">
              <label for="password">كلمة السر</label>
              <label
                for="password"
                class="float-left text-green-750"
              >نسيت كلمة السر؟</label>
            </div>
            <input
              v-model="password"
              class="py-4 px-2 rounded-lg bg-gray-150 outline-none"
              type="password"
              name="password"
              required
            >
          </div>

          <p
            v-if="invalidPassword"
            class="text-red-600"
          >
            حدث خطأ في تسجيل الدخول، برجاء التأكد من كلمة المرور
          </p>
          <input
            class="py-3 px-8 my-2 bg-green-750 rounded-lg text-white outline-none shadow-xl cursor-pointer"
            type="submit"
            value="تسجيل دخول"
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let instance = '';

export default {
  name: 'Login',
  emits: ['setAuthentication'],
  data: () => {
    return {
      branchesList: '',
      password: '',
      selectedBranch: '',
      invalidPassword: false,
    };
  },

  mounted: function () {
    this.configureAxiosInstance();
    this.getLocationDetails();
  },

  methods: {
    configureAxiosInstance() {
      instance = axios.create({
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },

    getAuth() {
      return instance({
        method: 'post',
        url: '/api/oauth/token',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          username: this.selectedBranch,
          password: this.password,
          scope: '',
        },
      })
        .then(response => {
          this.$emit('setAuthentication', response.data);
        })
        .catch(() => {
          this.invalidPassword = true;
          document.querySelector('input[type=password]').style.border = '1px solid red';

          setTimeout(() => {
            this.invalidPassword = false;
            this.password = '';
            document.querySelector('input[type=password]').focus();
            document.querySelector('input[type=password]').style.border = '';
          }, 3000);
        });
    },

    getLocationDetails() {
      instance.get('/api/locations').then(response => {
        this.branchesList = response.data;
      });
    },
  },
};
</script>

<style scoped>
input[type='submit']:active {
  box-shadow: none;
}
</style>
