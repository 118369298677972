<template>
  <div class="bg-white flex justify-between">
    <div class="flex justify-between">
      <div class="flex">
        <img
          src="@/assets/min/menu.svg"
          class="bg-green-950 p-5 inline cursor-pointer"
          alt="Toggle Menu Sign"
          @click="emitOpenMenuEvent"
        />

        <span class="inline-block text-xl bg-green-750 py-6 px-3 lg:p-6 text-center font-bold text-white">
          {{ categoryName }}
        </span>
      </div>
      <div class="flex items-center justify-center mr-2 lg:mr-10">
        <span class="text-xl mx-2">قيد التحضير</span>
        <div
          class="py-1 bg-green-750 text-white rounded-full font-bold"
          :class="{
            'px-2': inPreparationOrdersCount > 9,
            'px-3': inPreparationOrdersCount < 10,
          }"
        >
          {{ inPreparationOrdersCount }}
        </div>
      </div>
    </div>

    <div class="flex justify-evenly xl:w-3/12 md:w-4/12 sm:w-5/12 items-center">
      <button
        class="refresh-btn border-2 border-green-750 p-2 rounded-lg shadow-xl focus:outline-none"
        @click="emitRefreshEvent()"
      >
        <img src="@/assets/min/refresh.svg" alt="Refresh Arrows" />
      </button>

      <button
        class="pull-btn py-2 pl-2 lg:px-4 flex items-center bg-green-750 text-white rounded-lg focus:outline-none"
        :disabled="!allOrdersCount"
        :class="[allOrdersCount ? 'shadow-xl' : '']"
        @click="emitNewOrderEvent()"
      >
        <span class="new-order-content mx-2"> ابدأ طلب جديد </span>
        <div
          class="py-1 bg-white text-green-750 rounded-full font-bold"
          :class="{
            'px-2': allOrdersCount > 9,
            'px-3': allOrdersCount < 10,
          }"
        >
          {{ allOrdersCount }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryName: { type: String, required: true },
    allOrdersCount: { type: Number, required: true },
    inPreparationOrdersCount: { type: Number, required: true },
    disableRefresh: { type: Boolean, required: false, default: false },
  },
  emits: ['openMenuClick', 'newOrderClick', 'refreshClick'],
  methods: {
    emitOpenMenuEvent(e) {
      this.$emit('openMenuClick');
      e.stopPropagation();
    },

    emitNewOrderEvent() {
      this.$emit('newOrderClick');
    },

    emitRefreshEvent() {
      this.$emit('refreshClick');
    },
  },
};
</script>

<style>
.mark {
  margin: auto;
}

.pull-btn:active {
  box-shadow: none;
  outline: none;
  background-color: #005931;
}

.refresh-btn:active {
  box-shadow: none;
  outline: none;
}

.refresh-btn:hover {
  background: white;
}

.new-order-content::before {
  content: ' ';
  background: url('../assets/min/plus-order.svg') center no-repeat;
  padding: 0px 7px 3px;
  margin: 10px;
}
</style>
